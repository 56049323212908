import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Button } from '../../../UIKit/button/Button';
import { usePopupIframe } from '../../../../modal/popIframe';

export const Statistics: React.FC = () => {
    const { $ }: any = useLang();
    const { popIframe } = usePopupIframe();

    const statistic = [
        {
            percent: $('POSTPARTUM_STATISTIC_PER_1'),
            text: $('POSTPARTUM_STATISTIC_1'),
        },
        {
            percent: $('POSTPARTUM_STATISTIC_PER_2'),
            text: $('POSTPARTUM_STATISTIC_2'),
        },
        {
            percent: $('POSTPARTUM_STATISTIC_PER_3'),
            text: $('POSTPARTUM_STATISTIC_3'),
        },
        {
            percent: $('POSTPARTUM_STATISTIC_PER_4'),
            text: $('POSTPARTUM_STATISTIC_4'),
        },
    ]

    return (
        <div className='w-full max-w-content flex flex-col items-center pb-16 pt-20 px-6 lg:bg-parents-hiw-bg-md md:bg-[position:top_left,top_right] bg-no-repeat'>
            <Title className='text-2xl md:text-3xl max-w-[990px] pb-10 m-0' text={$('POSTPARTUM_STATISTICS_TITLE')} />
            <div className='max-w-[1210px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-14 gap-6 mb-16'>
                {
                    statistic.map(({ percent, text }, index) => (
                        <div className='text-center max-w-[260px]' key={index}>
                            <h6 className='text-[70px] text-primary-500 font-bold'>{percent}</h6>
                            <p className='text-lg font-bold'>{text}</p>
                        </div>
                    ))
                }
            </div>
            <div>
                <Button
                    className="w-full flex items-center justify-center rounded-2xl !text-base font-bold px-6 py-4  hover:bg-primary-400"
                    onClick={() => popIframe("client-secure")}
                >
                    {$('POSTPARTUM_START_THE_FREE_SURVEY')}
                </Button>
            </div>
        </div>
    );
};
