import { Footer } from "../../layout/footer/Footer"
import { Header } from "../../layout/header/Header"
import { Cards } from "./components/Cards"
import { Points } from "./components/Points"
import { Steps } from "./components/Steps"
import { Support } from "./components/Support"
import { WhatNext } from "./components/WhatNext"

export const PostSurvey = () => {
    return (
        <div>
            <WhatNext />
            <Steps />
            <Support />
            <Points />
            <Cards />
        </div>
    )
}