import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { Link } from 'react-router-dom';
import { Button } from '../../../UIKit/button/Button';
import babyFeedingPNG from '../../../../assets/images/baby-feeding.png';
import babyFeedingSMPNG from '../../../../assets/images/baby-feeding-sm.png';
import { usePopupIframe } from '../../../../modal/popIframe';

export const Caring: React.FC = () => {
    const { getSelectedLang, $ }: any = useLang();
    const { popIframe } = usePopupIframe();

    return (
        <div className='w-full max-w-content bg-blue-light flex flex-col md:flex-row justify-between items-center px-6 lg:px-20 xl:px-40 py-20'>
            <div>
                <Title className='md:text-left text-2xl md:text-[31px] mt-0 mb-6' text={$('POSTPARTUM_CARING_TITLE')} />
                <p className='text-gray-300 text-lg text-center md:text-left max-w-[670px] md:mb-14'>{$('POSTPARTUM_CARING_SUBTITLE')}</p>
                <img src={babyFeedingSMPNG} alt='' className='h-fit my-8 mx-auto md:hidden' />
                <div className='flex gap-4 flex-col md:flex-row'>
                    <Link to={getSelectedLang().url || '/'} className="w-full md:w-fit">
                        <Button
                            className="w-full  md:w-fit flex items-center justify-center rounded-2xl !text-base font-bold px-6 py-4  hover:bg-primary-400"
                        >
                            {$('POSTPARTUM_HOME_PHONE_START_SURVEY')}
                        </Button>
                    </Link>
                    <Button
                        className="w-full  md:w-fit flex items-center justify-center rounded-2xl !text-base font-bold px-6 py-4  hover:bg-primary-400"
                        onClick={() => popIframe("client-secure")}
                    >
                        {$('POSTPARTUM_BOOK_APPOINTMENT')}
                    </Button>
                </div>
            </div>
            <img src={babyFeedingPNG} alt='' className='h-fit hidden md:block' />
        </div>
    );
};
