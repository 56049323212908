import React from 'react';
import { Button } from '../../../../../UIKit/button/Button';
import { useLang } from '../../../../../../Lang/useLang';
import { twMerge } from 'tailwind-merge';
import { usePopupIframe } from '../../../../../../modal/popIframe';

export const Titles: React.FC = () => {
  const { $ }: any = useLang();
  const { popIframe } = usePopupIframe();

  return (
    <div className={twMerge(
      'w-full flex flex-col items-start z-20 gap-y-3 md:gap-y-6',
      'px-11 md:px-0 relative mt-auto mx-auto md:mt-[200px] md:ml-[160px] md:w-[550px]'
    )}>
      <div className={twMerge(
        'text-[40px] leading-[46px] font-semibold',
        'md:text-6xl text-white ',
      )}
      >
        {$('HOME_HEAD_TITLE')}
      </div>

      <div className={twMerge(
        'text-2xl text-zinc-100'
      )}
      >
        {$('HOME_HEAD_SUBTITLE')}
      </div>
      <div>
        <Button
          className="cta_click !w-full flex py-4 px-5 font-bold mt-8 hover:bg-primary-400 bg-primary-500"
          position="HOME_HEAD_BTN"
          onClick={() => popIframe("client-secure")}
        >
          {$('HOME_HEAD_BTN')}
        </Button>
      </div>
    </div>
  );
};
