import { BannerBlue, H1, H2 } from "../../../UIKit"
import { Rows } from "../../../UIKit/Line/Line"
import SwiggleMid from '../../../../assets/svgs/swiggle-mid.svg'
import SwiggleMidRight from '../../../../assets/svgs/swiggle-mid-right.svg'
import SwiggleMidLeft from '../../../../assets/svgs/swiggle-mid-left.svg'
import StepsCover from '../../../../assets/svgs/steps-cover.svg'
import StepsFollow from '../../../../assets/svgs/steps-follow.svg'
import StepsLamp from '../../../../assets/svgs/steps-lamp.svg'
import { Step } from "../uikit/step/Step"
import { StepsWrap } from "../uikit/stepsWrap/StepsWrap"
import { MinusTop } from "../uikit/minusTop/MinusTop"
import { useLang } from "../../../../Lang/useLang"
import { Link } from "react-router-dom"
import { usePopupIframe } from "../../../../modal/popIframe"



export const Steps = () => {
    const { $ } = useLang();
    const { popIframe } = usePopupIframe();

    return (
        <BannerBlue>
            <Rows>
                <StepsWrap>
                    <Step
                        iconSrc={StepsLamp}
                        swiggleSrc={SwiggleMidLeft}
                        text={$('POST_STEPS_1')}
                        num="01"
                    />
                    <Step
                        iconSrc={StepsFollow}
                        swiggleSrc={SwiggleMid}
                        text={$('POST_STEPS_2')}
                        num="02"
                    />
                    <Step
                        iconSrc={StepsCover}
                        swiggleSrc={SwiggleMidRight}
                        text={$('POST_STEPS_3')}
                        num="03"
                    />
                </StepsWrap>
                <MinusTop>
                    <H2>{$('POST_STEPS_LINK', {
                        link: (text: string) => <Link onClick={() => popIframe("client-secure")} to="#" className="primary">{text}</Link>
                    })}</H2>
                </MinusTop>
            </Rows>
        </BannerBlue>
    )
}