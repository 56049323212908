import { createContext, useContext, useState } from "react"
import { createPortal } from "react-dom"
import styles from './popIframe.module.css';
import { XIcon } from "lucide-react";
import { useLang } from "../Lang/useLang";
import { Iframe } from "../components/UIKit/iframe/Iframe";
import { Navigate } from "react-router-dom";
import { LINKS } from "../types/links";

interface IContext {
    popIframe: (cta: TCta) => void;
    hidePopIframe: () => void;
}

type TCta = "how-it-works" | "header" | "section" | "client-secure";

interface IPopIframe {
    cta: TCta
}

interface IPopIframeContent {
    cta: TCta,
    countryCode: string
}

export const popIframeContext = createContext<IContext | null>(null);

const Provider = popIframeContext.Provider;

export const PopIframeProvider = ({ children }: { children: any }) => {
    const [show, setShow] = useState<boolean>(false);
    const [cta, setCTA] = useState<TCta>("header");

    const value = {
        popIframe: (cta: TCta) => {
            setShow(true);
            setCTA(cta);
        },
        hidePopIframe: () => setShow(false)
    }
    return (
        <Provider value={value}>
            {show && <PopIframe cta={cta} />}
            {children}
        </Provider>
    )
}

export const usePopupIframe = (): IContext => {
    const state = useContext(popIframeContext);
    if (!state) {
        throw new Error("pop up iframe cannot be null");
    }

    return state;
}

export const PopIframe = ({ cta }: IPopIframe) => {
    const { hidePopIframe } = usePopupIframe();
    const { country, countries } = useLang();

    const countryCode = countries.find(i => i._id === country)?.key as string || 'us';

    if (isInternalFlow(cta, countryCode)) {
        hidePopIframe();
        return (
            <PopIframeContent
                cta={cta}
                countryCode={countryCode}
            />
        )
    }

    return (
        createPortal((
            <div className={styles.popIframe}>
                <div className={styles.container}>
                    <div className={styles.top}>
                        <XIcon onClick={hidePopIframe} />
                    </div>
                    <div className={styles.content}>
                        <PopIframeContent
                            cta={cta}
                            countryCode={countryCode}
                        />
                    </div>
                </div>
            </div>
        ), document.getElementById("modal")!)
    )
}

const isInternalFlow = (cta: TCta, countryCode: string) => {
    if (cta === "section" && countryCode === "us") {
        return true;
    }
    return false;
}

const PopIframeContent = ({ cta, countryCode }: IPopIframeContent) => {

    switch (cta) {
        case "client-secure":
            return countryCode === 'au' ? (
                <Iframe
                    title="client-secure"
                    src={LINKS.CLIENT_SECURE}
                />
            ) : (
                <Iframe
                    title="client-secure"
                    src={LINKS.CLIENT_SECURE}
                />
            )

        case "header":
            return countryCode === 'au' ? (
                <Iframe
                    title="cliniko"
                    id='cliniko-51567874'
                    src={LINKS.CLINIKO}
                />
            ) : (
                <Iframe
                    title="zocdoc"
                    src={LINKS.ZOCDOC}
                />
            )


        case "section":
            return countryCode === 'au' ? (
                <Iframe
                    title="cliniko"
                    id='cliniko-51567874'
                    src={LINKS.CLINIKO}
                />
            ) : (
                <Navigate to={LINKS.APPOINTMENT} />
            )

        case "how-it-works":
            return countryCode === 'au' ? (
                <Iframe
                    title="diagnostics"
                    src={LINKS.CLINIKO}
                />
            ) : (
                <Iframe
                    title="zocdoc"
                    src={LINKS.ZOCDOC}
                />
            )
        default:
            return <></>;
    }
}

