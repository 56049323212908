import React from 'react';
import { useLang } from '../../../../Lang/useLang';
import { Title } from '../../../UIKit/title/Title';
import { ReactComponent as ArrowSVG } from '../../../../assets/svgs/arrow-right.svg'
import { Link } from 'react-router-dom';
import mobilePNG from '../../../../assets/images/mobile.png';
import computerPNG from '../../../../assets/images/computer.png';
import tabletPNG from '../../../../assets/images/tablet.png';
import { LINKS } from '../../../../types/links';
import { usePopupIframe } from '../../../../modal/popIframe';


export const HowItWorks: React.FC = () => {
    const { getSelectedLang, $ }: any = useLang();
    const { popIframe } = usePopupIframe();

    const steps = [
        {
            step: 'POSTPARTUM_STEP',
            title: $('POSTPARTUM_HIW_1_TITLE'),
            description: $('POSTPARTUM_HIW_1_SUBTITLE'),
            linkText: $('POSTPARTUM_GET_STARTED'),
            link: getSelectedLang().url,
            action: null,
            image: mobilePNG
        },
        {
            step: 'POSTPARTUM_STEP',
            title: $('POSTPARTUM_HIW_2_TITLE'),
            description: $('POSTPARTUM_HIW_2_SUBTITLE'),
            linkText: $('POSTPARTUM_HIW_2_BUTTON'),
            link: LINKS.APPOINTMENT,
            action: () => popIframe("client-secure"),
            image: computerPNG
        },
        {
            step: 'POSTPARTUM_STEP',
            title: $('POSTPARTUM_HIW_3_TITLE'),
            description: $('POSTPARTUM_HIW_3_SUBTITLE'),
            linkText: $('POSTPARTUM_TAKE_THE_SURVEY'),
            link: LINKS.APPOINTMENT,
            action: () => popIframe("client-secure"),
            image: tabletPNG
        },
    ]

    return (
        <div className='w-full max-w-content bg-blue-light flex flex-col items-center py-8 md:pt-32 px-6'>
            <Title className='text-[29px] md:text-[40px] mb-0 md:mb-16 mt-0' text={$('POSTPARTUM_HIW_TITLE')} />
            <div className='max-w-[830px] w-full'>
                {
                    steps.map(({ step, title, description, linkText, link, image, action }, index) => (
                        <div className='flex flex-col-reverse items-center md:flex-row justify-between py-4 md:py-16 gap-y-4' key={index}>
                            <div className='max-w-[400px] flex flex-col items-center md:block'>
                                <h6 className='text-2xl md:text-[32px] text-primary-500 font-medium'>{$(step, index + 1)}</h6>
                                <Title className='mt-4 mb-3.5 text-center md:text-left text-2xl md:text-[31px]' text={title} />
                                <p className='text-gray-300 mb-5 text-center md:text-left'>{description}</p>
                                {action ? (
                                    <Link to={"#"} onClick={action} className='text-primary-500 text-xl font-bold flex items-center gap-x-2.5'>
                                        {linkText}
                                        <ArrowSVG className='self-end' />
                                    </Link>
                                ) : (
                                    <Link to={link} className='text-primary-500 text-xl font-bold flex items-center gap-x-2.5'>
                                        {linkText}
                                        <ArrowSVG className='self-end' />
                                    </Link>
                                )}
                            </div>
                            <div className='min-w-[344px] flex justify-center'>
                                <img src={image} alt='' />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};
